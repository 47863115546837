import React from 'react'
import colors from '../../Colors.scss'

const FacebookIcon = ({ color = colors.color_four }) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" fill="none">
            <path
                d="M11.3112 0.147461V3.38853H9.38379C8.67992 3.38853 8.20522 3.53585 7.95968 3.8305C7.71415 4.12514 7.59138 4.5671 7.59138 5.15639V7.4767H11.1885L10.7097 11.1106H7.59138V20.4287H3.83468V11.1106H0.704102V7.4767H3.83468V4.80036C3.83468 3.27804 4.26028 2.09742 5.11147 1.25851C5.96266 0.419598 7.09621 0.000139236 8.51214 0.000139236C9.71526 0.000139236 10.6483 0.0492477 11.3112 0.147461Z"
                fill={color}/>
        </svg>
  )
}

export default FacebookIcon
