import React from 'react'
import BackArrowIcon from '../../images/icons/BackArrowIcon'

import './Events.scss'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '../../images/icons/CloseIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  onChangeFilterEventIdAll,
  onChangeFilterOffsetAll,
  onChangeFilterSearchValue
} from '../../redux/slices/eventsSlice/eventsSlice'
import ReminderModal from '../../components/ReminderModal'
import Pagination from '@mui/material/Pagination'
import { ThemeProvider } from '@mui/material'
import Events from '../HomePage/Events/Events'
import { CookieBanner } from '../../components/cookieBanner/CookieBanner'
import { appTheme } from '../../theme'

const EventsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchValue = useSelector((state) => state.eventSliceReducer.searchValue)
  const { isOpenReminderModal } = useSelector((state) => state.eventSliceReducer)
  const { limit } = useSelector((state) => state.eventSliceReducer.filterAll)
  const totalEvents = useSelector((state) => state.eventSliceReducer.totalEvents)

  return (
        <div className='resent-events'>
            <div className='resent-events__back-icon' onClick={() => navigate(-1)}>
                <BackArrowIcon/>
            </div>
            {isOpenReminderModal && <ReminderModal/>}

            <div className='resent-events__search-bar-wrap'>
                <input type="text"
                       className='resent-events__search-bar'
                       placeholder='Suchen'
                       value={searchValue}
                       onChange={(e) => {
                         dispatch(onChangeFilterEventIdAll(null))
                         dispatch(onChangeFilterSearchValue(e.target.value))
                       }}
                />
                <div className='resent-events__close-icon-wrap'
                     onClick={() => dispatch(onChangeFilterSearchValue(''))}>
                    <CloseIcon/>
                </div>
            </div>
            <div className='event-with-pagination'>
                <Events/>
                {totalEvents && limit && totalEvents / limit !== 0 &&
                    <div className='resent-events__pagination-controller'>
                         <ThemeProvider theme={appTheme}>
                        <Pagination
                            count={totalEvents < limit ? 1 : Math.ceil(totalEvents / limit)}
                            color="primary"
                            onChange={(e, val) => dispatch(onChangeFilterOffsetAll((val - 1) * limit))}/>
                         </ThemeProvider>
                    </div>}
            </div>
            <CookieBanner/>
        </div>
  )
}

export default EventsPage
