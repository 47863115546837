import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Material UI components
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CloseIcon from '@mui/icons-material/Close'

// React Intl
// Styles
import './Header.scss'

// Local Components
// SVG
// Redux Actions
import { addEventId, addLastUrl } from '../../redux/slices/globalValue/globalValue'
import { addSendBird } from '../../redux/slices/SendBirdSlice/SendBirdSlice'
import { connectToSB } from '../../pages/InboxPage/SendBird/action'

// Queries
import { useGetDetailUserQuery } from '../../redux/api/userApi/userApi'
import { useGetAttendeeProfileQuery } from '../../redux/api/authApi/authApi'

// Helpers
import { connectSbAndCheckMsgCount } from '../../helper/header/countUnreadMsg'

// Hooks
import { useAuthValue, useGlobalValue } from '../../hooks/redux/reduxHooks'
import HeaderActions from './headerActions/HeaderActions'
// import Logo from '../../images/icons/Logo'
import Logo2 from '../../images/cust/Kath_Kirche_Lebensraum_SG_Logo_Blau_RGB.png'
import Logo from '../../images/cust/icons/Logo'
import { useMediaQuery } from 'react-responsive'
import BackArrowIcon from '../../images/icons/BackArrowIcon'
import { onChangeFilterSearchValue } from '../../redux/slices/eventsSlice/eventsSlice'
import TextField from '@mui/material/TextField'
import SearchIcon from '../../SVG/global/SearchIcon'
import { FormattedMessage } from 'react-intl'

const Header = () => {
  // hooks
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location

  // custom hooks
  const { eventId } = useGlobalValue()
  const { login, userId } = useAuthValue()

  // url
  const href = document.location.href.split('/')
  const chatHref = href[href.length - 2]

  // state
  const [open, setOpen] = useState({ header: false, anchor: null })
  const sendBird = useSelector(state => state.sendBirdSliceReducer.sendBird)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1300px)' })
  // api
  const { refetch } = useGetDetailUserQuery('', {
    skip: !login
  })
  const { data: profileDetailsSB } = useGetAttendeeProfileQuery(userId, {
    skip: !login
  })

  const [showSearchField, setShowSearchField] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (userId) {
      refetch()
    }
  }, [userId])

  const connectAndCheck = useCallback(async ({ sendBird, dispatch, href, profileDetailsSB, chatHref }) => {
    dispatch(addSendBird(sendBird))
    await connectSbAndCheckMsgCount({ sendBird, dispatch, href, profileDetailsSB, chatHref })
    dispatch(addLastUrl(''))
  }, [profileDetailsSB])

  useEffect(() => {
    (async () => {
      if (href.includes('events')) {
        const sendBird = await connectToSB()
        dispatch(addSendBird(sendBird))
        await connectAndCheck({ sendBird, dispatch, href, profileDetailsSB, chatHref })
      }
    })()
    dispatch(addLastUrl(''))
  }, [profileDetailsSB])

  useEffect(() => {
    if (href.includes('/events') && !eventId) {
      dispatch(addEventId({ eventId: href[4] }))
    }
    if (href.includes('hotline') || href.includes('inbox') && !sendBird) {
      (async () => {
        if (href.includes('events')) {
          const sendBird = await connectToSB()
          dispatch(addSendBird(sendBird))
          await connectAndCheck({ sendBird, dispatch, href, profileDetailsSB, chatHref })
        }
      })()
    }
  }, [href])

  const isReverse = false

  const pathsToTitles = {
    '/': 'Home',
    '/upcoming_events': 'Upcoming events',
    '/recent_events': 'Recent events',
    '#': 'Learn more'
  }

  useEffect(() => {
    const isOpen = open.header
    const body = document.getElementsByTagName('body')[0]

    if (isOpen) {
      body.classList.add('hidden-body')
    } else {
      body.classList.remove('hidden-body')
    }
  }, [open.header])

  return (
        <div className="header-page-container">
            <div className="header-page-main" id='header-height'
                 style={{ flexDirection: isReverse ? 'row-reverse' : 'row', alignItems: 'center', height: '80px' }}>
                {!isTabletOrMobile
                  ? <div className='logo-wrap' onClick={() => navigate('/')}>
                        <img src={Logo2} height='86px'/>
                        <Logo/>

                        {/* {!location.pathname.includes('events/') && <p className='logo-description'>Live broadcast</p>} */}
                        {!location.pathname.includes('events/') &&
                            <p className='logo-description'><FormattedMessage id="page-title"
                                                                              defaultMessage="Liveübertragungen"/>
                            </p>}
                    </div>
                  : <div className='back-icon' style={{
                    height: location.pathname === '/' && isTabletOrMobile ? 'fit-content' : '',
                    padding: location.pathname === '/' && isTabletOrMobile ? '0' : '',
                    marginRight: location.pathname === '/' && isTabletOrMobile ? '-20px' : ''
                  }} onClick={() => navigate(-1)}>
                        {location.pathname !== '/' && isTabletOrMobile ? <BackArrowIcon/> : <Logo/>}
                    </div>
                }

                <div className="desktop-head">
                    <HeaderActions/>
                </div>

                {isTabletOrMobile && !(location.pathname === '/') && !showSearchField && <div
                    className='header-title'>{location.pathname.includes('events/') ? 'Event Details' : pathsToTitles[location.pathname] || ''}</div>}

                <div className="mobile-head">
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <div className='searchWrap'>
                            {showSearchField &&
                                <TextField id="standard-basic"
                                           label="Suchen"
                                           value={searchValue}
                                           onChange={e => {
                                             setSearchValue(e.target.value)
                                           }}
                                           onKeyPress={(event) => {
                                             if (event.key === 'Enter') {
                                               if (searchValue) {
                                                 dispatch(onChangeFilterSearchValue(searchValue))
                                                 navigate('/events')
                                               } else {
                                                 setShowSearchField(prev => !prev)
                                               }
                                             }
                                           }}
                                           variant="standard"/>}
                            <div
                                onClick={() => {
                                  if (searchValue) {
                                    dispatch(onChangeFilterSearchValue(searchValue))
                                    navigate('/events')
                                  } else {
                                    setShowSearchField(prev => !prev)
                                  }
                                }}
                                className={`headerItemLink ${pathname === '/events' ? 'headerItemLinkActive' : ''}`}>
                                <SearchIcon fill={pathname === '/events' ? '$color-header-text' : null}/>
                            </div>
                        </div>
                        <div className="mobile-header-side">
                            {open.header
                              ? (
                                    <CloseIcon
                                        onClick={() => setOpen((prev) => ({ ...prev, header: false }))}
                                        fontSize="large"
                                    />
                                )
                              : (
                                    <MenuOutlinedIcon
                                        onClick={() => setOpen((prev) => ({ ...prev, header: true }))}
                                        fontSize="large"
                                    />
                                )}
                        </div>
                    </div>

                </div>

            </div>
            <div className="mobile-head2">
                {open.header ? (
                    <div className="header-page-actions">

                        <div className="header-logout-container">
                            <div className="header-nav-items-wrap">
                                {/* <p><FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"/></p> */}
                                {/* <p><FormattedMessage id="about-us" defaultMessage="About Us"/></p> */}
                                {/* <p><FormattedMessage id="contact-us" defaultMessage="Contact Us"/></p> */}
                                {/* <p><FormattedMessage id="action-link" defaultMessage="Action Link"/></p> */}
                                <a href='/' className='headerItemLink'>Home</a>

                                <a href='/upcoming_events' className='headerItemLink'
                                    // rel="noreferrer">Upcoming events</a>
                                   rel="noreferrer">Live-Übertragungen</a>

                                <a href='/recent_events' className='headerItemLink'
                                    // rel="noreferrer">Recent events</a>
                                   rel="noreferrer">Aufzeichnungen</a>

                                {/* <a href='https://www.bistum-stgallen.ch/' className='headerItemLink' */}
                                {/*   rel="noreferrer">Webseite</a> */}

                            </div>
                        </div>
                    </div>
                ) : (
                  ''
                )}
            </div>
        </div>
  )
}
export default Header
