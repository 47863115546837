import React from 'react'

const Logo = () => {
  return (
        // <svg xmlns="http://www.w3.org/2000/svg" width="151" height="56" viewBox="0 0 88 56" fill="none">
        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="150px" height="112px" viewBox="0 0 130.72 83.88">
            {/* <defs> */}
            {/*    <style>.bistlogo-1{fill:#9b1889;}</style> */}
            {/* </defs> */}
            <title>Logo_violett</title>
            <path
                  d="M55.36,51.41A12.39,12.39,0,0,1,46.28,55c-4.08,0-7.16-1.54-8.47-4.31-1-2.16-1.31-4.54-1.31-10.62,0-8.47.31-14.63.39-16.56L37,22H33.19c-1.54,0-2.39-.61-2.39-1.77,0-1.46.31-1.69,2.77-2.23,5.78-1.23,8.63-3.78,8.63-8s-3.31-6.79-8.32-6.79c-6.31,0-10.09,4.16-10.09,11.29a29,29,0,0,0,1.15,9.16,9.25,9.25,0,0,1,.7,2.84,1.27,1.27,0,0,1-1.14,1.39,1.71,1.71,0,0,1-.32,0c-1.38,0-3.85-2.23-6-5.54C16.4,19.64,15.48,19,13.09,19c-3.46,0-5.85,2-5.85,5,0,2.7,1.39,4.16,5.39,5.93a37.73,37.73,0,0,1,9.63,5.31A10.7,10.7,0,0,1,26,43.67C26,50.9,21,55.6,13.32,55.6,9.4,55.6,7.32,54,5.47,54c-.61,0-1.92.39-2.54.39-1,0-1.54-.54-1.54-1.77,0-.46.08-1.39.08-1.62a29.22,29.22,0,0,0-1-6.16,4.39,4.39,0,0,1-.31-1.38A1.38,1.38,0,0,1,1.58,42.1h0c1.39,0,3.08,1.77,5.47,5.46,1.46,2.31,1.62,2.54,2.46,3.31A6.38,6.38,0,0,0,14,52.34c3.85,0,6.47-2.24,6.47-5.55A5.78,5.78,0,0,0,19,43.1c-1.23-1.31-2.62-2.16-6.93-4.39s-5.69-3-7.23-4.47a9.32,9.32,0,0,1-2.78-7c0-6.7,4.85-11.32,11.94-11.32,2.85,0,4.23.85,5.23.85a1.31,1.31,0,0,0,1-.54c.62-.7.85-1.24,1.39-4.16.85-4.16,1.77-6.32,3.62-8.32A12.45,12.45,0,0,1,34.5.06c6.39,0,10.39,3.92,10.39,10a29.31,29.31,0,0,1-1,7.39s.62.07,1.23.07c3.93,0,8.7-.53,9.47-.53,1.39,0,1.85.61,1.85,2.38,0,2.16-.46,2.93-1.77,2.93-1.54,0-5-.31-9.78-.31H43.51V37.55c0,4.93.15,6.7.61,8.47a4.91,4.91,0,0,0,5.09,3.62c3.69,0,6.08-2.85,6.92-2.85a1.31,1.31,0,0,1,1.08,1.39A5.4,5.4,0,0,1,55.36,51.41Z"
                   fill="#9b1889"
                  transform="translate(-0.13 -0.05)"/>
            <path
                  d="M5,79.28A5.57,5.57,0,0,1,3.28,79a2.8,2.8,0,0,0-.76-.17.67.67,0,0,0-.2,0L2,78.93H1.8a.38.38,0,0,1-.38-.36h0a1.74,1.74,0,0,1,.05-.39A5.11,5.11,0,0,0,1.54,77V68.26a2.06,2.06,0,0,0,0-.7c-.07-.15-.32-.3-.8-.48s-.61-.28-.61-.54.15-.42.74-.48a4.17,4.17,0,0,0,1.43-.35l.34-.21a.49.49,0,0,1,.29-.06c.28,0,.46.15.46.39a1,1,0,0,1,0,.24v.41a1.85,1.85,0,0,1-.06.59v4a4.27,4.27,0,0,1,2.83-1.24c2.1,0,3.58,1.89,3.58,4.57C9.66,77.38,7.85,79.28,5,79.28ZM5.13,71a3,3,0,0,0-1.88.7v5.7l.16.14a4.54,4.54,0,0,0,.48.39,2.56,2.56,0,0,0,1.48.5c1.52,0,2.42-1.38,2.42-3.66S6.79,71,5.13,71Z"
                   fill="#9b1889"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M15.05,79.12h-.28a11.56,11.56,0,0,0-1.44-.06,13.56,13.56,0,0,0-1.74.06h-.24c-.3,0-.44-.11-.44-.35s.14-.41.48-.41h.13c.7,0,.81-.11.81-.91V72.31c0-.5-.15-.63-.74-.76s-.72-.24-.72-.55.17-.44.65-.44A2.71,2.71,0,0,0,13,70.28l.46-.28a.41.41,0,0,1,.26-.07.33.33,0,0,1,.29.13.3.3,0,0,1,.15.26.43.43,0,0,1,0,.16,5.15,5.15,0,0,0-.09,1.24v6c0,.5.11.65.59.65h.05c.56,0,.74.11.74.39S15.36,79.12,15.05,79.12Zm-1.8-10.91A1.19,1.19,0,1,1,14.44,67,1.21,1.21,0,0,1,13.25,68.21Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M19.86,79.41a3.66,3.66,0,0,1-1.31-.24,1.61,1.61,0,0,0-.61-.15h-.36a.57.57,0,0,1-.26,0c-.25,0-.36-.13-.36-.43v-.48a6.14,6.14,0,0,0-.23-1.28,2,2,0,0,1-.09-.33.35.35,0,0,1,.37-.31c.31,0,.74.42,1.31,1.31a3.16,3.16,0,0,0,.6.8,1.54,1.54,0,0,0,1.09.35,1.37,1.37,0,0,0,1.52-1.2.49.49,0,0,0,0-.12,1.26,1.26,0,0,0-.26-.77,4.76,4.76,0,0,0-1.75-1.15,6.12,6.12,0,0,1-1.74-1.08,2.28,2.28,0,0,1-.69-1.67A2.68,2.68,0,0,1,19.76,70h.14a3.74,3.74,0,0,1,.81.1l.39.09h.2a.58.58,0,0,0,.26-.06L21.8,70a.34.34,0,0,1,.2,0c.24,0,.37.18.39.59a4.36,4.36,0,0,0,.21,1.24,2.65,2.65,0,0,1,.16.63.39.39,0,0,1-.36.42h0c-.33,0-.9-.54-1.4-1.31s-.69-.8-1.26-.8a1.23,1.23,0,0,0-1.36,1.08.41.41,0,0,0,0,.11c0,.61.35,1,1.29,1.38A8.69,8.69,0,0,1,22,74.6a2.54,2.54,0,0,1,.88,2,2.77,2.77,0,0,1-2.7,2.82Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M30.16,78.41a3,3,0,0,1-2.18.87,2.12,2.12,0,0,1-2-1,5.83,5.83,0,0,1-.31-2.55V73.21l0-1.42V71.4h-.79c-.56,0-.71-.11-.71-.48a.61.61,0,0,1,0-.19c.07-.14.13-.16.68-.33a1.93,1.93,0,0,0,1.39-1.53c.17-.63.3-.78.65-.78s.44.11.44.39v.24a10.26,10.26,0,0,0-.09,1.57h2.63c.35,0,.46.15.46.57s-.11.7-.42.7h-2.7V75.3a7.89,7.89,0,0,0,.13,2,1.19,1.19,0,0,0,1.24.87,2.59,2.59,0,0,0,1.46-.59.4.4,0,0,1,.21-.09.21.21,0,0,1,.18.11.27.27,0,0,1,.08.22A1.32,1.32,0,0,1,30.16,78.41Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M40.79,78.5l-.33.08a3.64,3.64,0,0,0-1.28.42c-.44.22-.55.28-.76.28a.42.42,0,0,1-.42-.46,2.8,2.8,0,0,1,0-.41v-.7l-.28.2-.8.67a2.64,2.64,0,0,1-1.88.75c-1.63,0-2.43-1-2.43-3.07V72a4.51,4.51,0,0,0,0-.52c0-.42-.24-.61-.68-.61h-.3c-.33,0-.46-.13-.46-.4s.11-.41.37-.41h2a3.53,3.53,0,0,0,.46,0h.15a.3.3,0,0,1,.33.28v4.83c0,2.09.39,2.83,1.5,2.83a3.16,3.16,0,0,0,2.15-.93v-5.4c0-.46,0-.57-.1-.68s-.25-.17-.7-.17H37c-.3,0-.43-.13-.43-.4S36.7,70,37,70h2.46c.43,0,.52,0,.52.37a4.09,4.09,0,0,1-.06.59,10.35,10.35,0,0,0-.07,1.35v4.27c0,.56,0,.67.09.78a1.42,1.42,0,0,0,1,.24c.37,0,.48.09.48.37S41.09,78.4,40.79,78.5Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M57,79.12h-.13l-.7-.06H54.3l-.67.06h-.22c-.26,0-.35-.09-.35-.37s.09-.35.43-.37h.33c.39,0,.48-.11.48-.5a2.54,2.54,0,0,0,0-.39v-4a3.74,3.74,0,0,0-.19-1.66,1.42,1.42,0,0,0-1.4-.69,3.14,3.14,0,0,0-1.94.74v6.09c0,.37.09.4.67.4h.05c.39,0,.54.13.54.43a.34.34,0,0,1-.33.35h-.23L50.79,79h-.87a7.42,7.42,0,0,0-1.41.08h-.26a.37.37,0,0,1-.39-.33v0c0-.26.13-.37.49-.39h.29c.37,0,.46-.13.48-.52V73.29a3.29,3.29,0,0,0-.24-1.64,1.36,1.36,0,0,0-1.26-.56,3,3,0,0,0-1.37.33,4.68,4.68,0,0,0-.72.47V78c0,.28.22.39.72.39h.06c.39,0,.52.07.52.35s-.13.39-.41.39h-.11c-.5,0-1-.08-1.52-.08H43.18a.67.67,0,0,1-.2,0,.38.38,0,0,1-.41-.33v-.06c0-.26.15-.37.5-.37a2.09,2.09,0,0,0,.5,0,.22.22,0,0,0,.19-.13,1.11,1.11,0,0,0,.07-.26V72.59c0-.54,0-.61-.09-.74a2.25,2.25,0,0,0-1-.5c-.29-.09-.37-.18-.37-.39s.15-.39.67-.46l.46-.07a3.11,3.11,0,0,0,1.07-.34c.37-.2.45-.22.61-.22a.42.42,0,0,1,.43.41v.22a4.86,4.86,0,0,0,0,.54v.33l.19-.16a6.51,6.51,0,0,1,.76-.63,3,3,0,0,1,2-.71,2.15,2.15,0,0,1,2.23,1.46l.24-.24c.24-.2.46-.39.67-.55a3.29,3.29,0,0,1,2.09-.71,2.22,2.22,0,0,1,2,1c.24.4.3.75.3,2v4.75c0,.47,0,.52.11.63a.8.8,0,0,0,.52.15.14.14,0,0,0,.09,0H57c.31,0,.4.11.4.41S57.22,79.12,57,79.12Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M76.06,78.41a3,3,0,0,1-2.19.87,2.1,2.1,0,0,1-2-1,5.83,5.83,0,0,1-.31-2.55c0-2,.07-3.52.09-4v-.37h-.91c-.37,0-.57-.15-.57-.42s.07-.41.66-.54c1.39-.3,2.07-.91,2.07-1.92s-.79-1.63-2-1.63c-1.52,0-2.43,1-2.43,2.7a7.15,7.15,0,0,0,.28,2.2,2.19,2.19,0,0,1,.17.68.31.31,0,0,1-.28.34h-.07c-.34,0-.93-.54-1.45-1.34-.42-.64-.64-.79-1.22-.79a1.24,1.24,0,0,0-1.39,1.07.53.53,0,0,0,0,.13c0,.65.33,1,1.29,1.43A8.69,8.69,0,0,1,68.1,74.5a2.52,2.52,0,0,1,.91,2,2.77,2.77,0,0,1-2.67,2.87H66A12.19,12.19,0,0,1,64.12,79c-.15,0-.46.1-.61.1a.4.4,0,0,1-.37-.43v-.39a7.37,7.37,0,0,0-.24-1.48,1,1,0,0,1-.11-.27.31.31,0,0,1,.33-.31h0c.33,0,.74.42,1.31,1.31a2.91,2.91,0,0,0,.59.8,1.54,1.54,0,0,0,1.07.35,1.38,1.38,0,0,0,1.55-1.19s0-.09,0-.14a1.4,1.4,0,0,0-.35-.89,5.64,5.64,0,0,0-1.67-1.06,6.63,6.63,0,0,1-1.74-1.07,2.2,2.2,0,0,1-.66-1.68,2.66,2.66,0,0,1,2.59-2.73h.27a6.62,6.62,0,0,1,1.26.2.28.28,0,0,0,.24-.13c.15-.16.21-.29.34-1a3.63,3.63,0,0,1,.87-2A3,3,0,0,1,71,66.08a2.28,2.28,0,0,1,2.49,2.07,1.82,1.82,0,0,1,0,.33,6.7,6.7,0,0,1-.24,1.78h.3c.94,0,2.09-.13,2.27-.13.33,0,.45.15.45.57s-.12.7-.43.7-1.2-.07-2.35-.07H73.2v3.74a7.47,7.47,0,0,0,.15,2,1.18,1.18,0,0,0,1.22.87c.88,0,1.46-.68,1.66-.68a.31.31,0,0,1,.26.33A1.32,1.32,0,0,1,76.06,78.41Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889" d="M78.53,79.25a1,1,0,1,1,1-1A1,1,0,0,1,78.53,79.25Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M89.29,71.72a1,1,0,0,1-.76-.39,2.72,2.72,0,0,0-.74-.53,3.85,3.85,0,0,0-1-.3,2.65,2.65,0,0,1,1.44,2.61,2.93,2.93,0,0,1-2.76,3.09h-.36a3.36,3.36,0,0,1-1.32-.28c-.53.18-.76.46-.76.9a.87.87,0,0,0,.69.87,6.53,6.53,0,0,0,1.76.17,6.92,6.92,0,0,1,2.31.24,2.41,2.41,0,0,1,1.48,2.4c0,2.08-1.87,3.43-4.68,3.43-2.4,0-3.9-.91-3.9-2.37,0-1.09.79-1.92,2.27-2.39-1-.35-1.62-1-1.62-1.81s.63-1.48,2-1.7a3.1,3.1,0,0,1-1.51-2.76,3,3,0,0,1,2.88-3.12,2.57,2.57,0,0,1,.48,0,8.85,8.85,0,0,1,2.35.35l.35.11h.17c.07,0,.15-.06.26-.19a1.19,1.19,0,0,1,.9-.46.93.93,0,0,1,.89,1h0a.91.91,0,0,1-.68,1.09Zm-2,8.16a4.6,4.6,0,0,0-2.5-.45H83.65a2.05,2.05,0,0,0-1.3,1.87c0,1.24,1,2,2.63,2s2.94-.83,2.94-2.07a1.41,1.41,0,0,0-.65-1.35Zm-2.16-9.47c-1,0-1.54.87-1.54,2.72,0,1.64.49,2.4,1.52,2.4s1.41-.78,1.41-2.7c0-1.7-.45-2.42-1.41-2.42Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M97.1,79.21c-.87,0-1.15-.31-1.21-1.33V77.6c-.16.17-.22.2-.53.48l-.61.54a2.08,2.08,0,0,1-1.5.66,1.91,1.91,0,0,1-1.89-1.93v-.07c0-1.61,1.67-2.83,4.59-3.34V72.18a1.21,1.21,0,0,0-.28-.68A2.14,2.14,0,0,0,94,70.81c-.63,0-1.16.26-1.16.57a.26.26,0,0,0,.11.21l.22.18a1,1,0,0,1,.37.78,1,1,0,0,1-1.09,1h-.06a1,1,0,0,1-1-1v-.07a1.73,1.73,0,0,1,.41-1.15,5.06,5.06,0,0,1,3.44-1.46,2.47,2.47,0,0,1,1.79.65,2.14,2.14,0,0,1,.55,1.79v1l-.08,3.18v.35c0,.92.09,1.14.59,1.14a2.25,2.25,0,0,0,.52-.07h.28a.35.35,0,0,1,.37.33v.06C99.28,78.73,98.24,79.21,97.1,79.21Zm-4-2.52c0,.73.42,1.24,1,1.24a2,2,0,0,0,1.13-.43l.55-.4.13-.1V74.54c-2,.36-2.84,1-2.84,2.15Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M104.39,79.1a1.93,1.93,0,0,1-.34,0,3.64,3.64,0,0,0-.66,0h-2.7a1,1,0,0,1-.24,0,.37.37,0,0,1-.37-.35v0c0-.26.17-.37.57-.37a1.89,1.89,0,0,0,.78-.13c.17-.13.2-.35.2-1.15V67.71c0-.5-.12-.56-.87-.71-.43-.07-.63-.24-.63-.51s.18-.45.77-.47a2.86,2.86,0,0,0,1.61-.44,1,1,0,0,1,.56-.22.37.37,0,0,1,.39.35V66a2,2,0,0,0,0,.35V77c0,1,0,1.06.19,1.2s.31.13.63.15c.5,0,.63.11.63.43S104.64,79.1,104.39,79.1Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M110.16,79.1a1.93,1.93,0,0,1-.34,0,3.64,3.64,0,0,0-.66,0h-2.7a1,1,0,0,1-.24,0,.37.37,0,0,1-.37-.35v0c0-.26.17-.37.57-.37a1.89,1.89,0,0,0,.78-.13c.17-.13.2-.35.2-1.15V67.71c0-.5-.11-.56-.87-.71-.43-.07-.63-.24-.63-.51s.19-.45.78-.47a2.86,2.86,0,0,0,1.61-.44,1,1,0,0,1,.55-.22.37.37,0,0,1,.39.35V66a2,2,0,0,0,0,.35V77c0,1,0,1.06.18,1.2s.32.13.63.15c.5,0,.63.11.63.43S110.42,79.1,110.16,79.1Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M119.61,73.87c-.07.12-.15.16-.48.16h-.41l-5.07-.09v.55c0,2.15,1,3.39,2.79,3.39a3.86,3.86,0,0,0,2.48-1c.2-.19.27-.24.4-.24a.28.28,0,0,1,.28.3v0a1.39,1.39,0,0,1-.33.74,3.9,3.9,0,0,1-3.33,1.68c-2.44,0-4-1.87-4-4.73s1.72-4.9,4.2-4.9a3.46,3.46,0,0,1,3.6,3.31v0A2.1,2.1,0,0,1,119.61,73.87Zm-3.75-3.28c-1.23,0-1.93.93-2.11,2.74l4-.13C117.79,71.5,117.08,70.59,115.86,70.59Z"
                  transform="translate(-0.13 -0.05)"/>
            <path fill="#9b1889"
                  d="M130.51,79.12h-.19c-.28,0-.94-.06-1.39-.06h-1a4,4,0,0,0-.69,0H127c-.26,0-.37-.09-.37-.35a.34.34,0,0,1,.08-.26.13.13,0,0,1,.11-.09.67.67,0,0,1,.2,0h.33c.39,0,.52-.13.52-.54a1.82,1.82,0,0,1,0-.33v-3.6a6.44,6.44,0,0,0-.11-1.58,1.47,1.47,0,0,0-1.61-1.16,3.49,3.49,0,0,0-2.07.79v5.88c0,.43.11.54.55.54h.24c.35,0,.48.13.48.42s-.13.34-.37.34h-.2a14.09,14.09,0,0,0-1.59-.06,11.11,11.11,0,0,0-1.5.06h-.2c-.3,0-.41-.11-.41-.39s.13-.37.5-.37c.68,0,.76-.09.76-.8v-5.3c0-.47-.19-.63-.76-.74s-.68-.23-.68-.5.18-.45.62-.5a4.68,4.68,0,0,0,1.47-.33l.44-.25a.55.55,0,0,1,.28-.07.32.32,0,0,1,.37.28v.28a6,6,0,0,0,0,.85v.13c1.37-1.19,2.07-1.54,3-1.54a2.62,2.62,0,0,1,2.09,1,4.06,4.06,0,0,1,.39,2.35v4.4a1.93,1.93,0,0,1,0,.34.47.47,0,0,0,.5.44h.37c.29,0,.42.13.42.41A.35.35,0,0,1,130.51,79.12Z"
                  transform="translate(-0.13 -0.05)"/>
        </svg>
  )
}

export default Logo
